import React, { useEffect } from "react";
import PersonalInfo from "../../components/agence/personal-info";
import SideMenu from "../../components/agence/side-menu";
import { useSelector, useDispatch } from "react-redux";
import { getUserData, getUserDataList } from "../../features/auth/agence";
import moment from "moment";

function Profil() {
  const dispatch = useDispatch();
  const useinfo = useSelector((state: any) => state.user.data);
  const locationinfo = useSelector((state: any) => state.user.locationList);

  useEffect(() => {
    dispatch<any>(getUserData());
    dispatch<any>(getUserDataList(null));
    console.log("locationinfo");
    console.log(locationinfo);
  }, [dispatch]);
  return (
    <div>
      {" "}
      <main className="main">
        <div className="user-profile py-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                 <SideMenu userData={useinfo} />
              </div>
              <div className="col-lg-9">
                <div className="user-profile-wrapper">
                  <div className="user-profile-wrapper">
                 {/*    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="dashboard-widget dashboard-widget-color-1">
                          <div className="dashboard-widget-info">
                            <h1>05</h1>
                            <span>Upcoming Booking</span>
                          </div>
                          <div className="dashboard-widget-icon">
                            <i className="fal fa-list"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="dashboard-widget dashboard-widget-color-2">
                          <div className="dashboard-widget-info">
                            <h1>1250</h1>
                            <span>Total Booking</span>
                          </div>
                          <div className="dashboard-widget-icon">
                            <i className="fal fa-eye"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="dashboard-widget dashboard-widget-color-3">
                          <div className="dashboard-widget-info">
                            <h1>110</h1>
                            <span>Cancel Booking</span>
                          </div>
                          <div className="dashboard-widget-icon">
                            <i className="fal fa-xmark-circle"></i>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="user-profile-card">
                          <h4 className="user-profile-card-title">
                            Upcoming Booking
                          </h4>
                          <div className="table-responsive">
                            <table className="table text-nowrap">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Info</th>
                                  <th>Journey Date</th>
                                  <th>Drop Off Date</th>
                                  <th>Montant</th>
                                  <th>Payée</th>
                                  <th>Statust</th>
                                </tr>
                              </thead>
                              <tbody>
                                {locationinfo.map((item: any) => {
                                  return (
                                    <>
                                      {" "}
                                      <tr>
                                      <td>
                                          <div className="table-list-info">
                                            <a href="#">
                                              <img
                                                src={item.vehicule.image}
                                                alt=""
                                              />
                                             
                                            </a>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="table-list-info">
                                            <a href="#">
                                            
                                              <div className="table-list-content">
                                                <h6>{item.vehicule.libelle}</h6>
                                                <span>Matricule: {item.vehicule.matricule }</span>
                                              </div>
                                            </a>
                                          </div>
                                        </td>
                                        <td>
                                          <span>{moment(item.date_solicitation).format('D MMMM, YYYY')}</span>
                                          <p>{item.date_solicitation}</p>
                                        </td>{" "}
                                        <td>
                                          <span>{moment(item.date_retour).format('D MMMM, YYYY')}</span>
                                          <p>{item.heure_solicitation}</p>
                                        </td>
                                        <td>{item.montant} Fcfa</td>
                                        <td>
                                        <span className={`badge  ${item.is_paid ? 'badge-primary' : 'badge-danger'}`}>
                                          {item.is_paid?"Yes":"Non"}
                                          </span>
                                        </td>{" "}
                                        <td>
                                          <span className="badge badge-primary">
                                          {item.status}
                                          </span>
                                        </td>
                                      </tr>{" "}
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Profil;
