import axios from "axios";

export default class ApiService {
    url = "https://devapi.ongo237.com/api/";
  //url = "http://127.0.0.1:8002/api/";
  // url = "https://api.ongo237.com/api/";

  urlimage = "https://api.ongo237.com/api/";

  baseUrl = `${this.url}`;
  //imageUrl = `${this.urlimage}storage/`;
  token = localStorage.getItem("token");

  axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${this.token}`,
    },
  };

  postData = async (route: string, data: any = {}) => {
    var token = localStorage.getItem("token");

    console.log(token);
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(this.baseUrl + route, data, this.axiosConfig);
  };

  postDatatoken = async (route: string, data: any, 
    token: any) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(this.baseUrl + route, data, config);
  };

  updateData = async (route: string, data: any) => {
    return await axios.put(this.baseUrl + route, data);
  };

  updateDatatoken = async (route: string, data: any, token: any) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.put(this.baseUrl + route, data, config);
  };

  uploadImage = async (data: any) => {
    let formData = new FormData();
    formData.append("file", data);
    return await axios.post(
      this.baseUrl + "v2/file-upload",
      formData,
      this.axiosConfig
    );
  };

  uploadPdf = async (data: any) => {
    let formData = new FormData();
    formData.append("pdf", data);

    return await axios.post(
      this.baseUrl + "uploadPdf",
      formData,
      this.axiosConfig
    );
  };

  getData = async (route: string) => {
    return await axios.get(this.baseUrl + route, this.axiosConfig);
  };
  getDatawithPagination = async (route: string, isPag: boolean) => {
    return await axios.get(
      isPag ? route : this.baseUrl + route,
      this.axiosConfig
    );
  };

  getDataWithToken = async (route: string, token: any) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (route.includes("http://") || route.includes("https://")) {
      return await axios.get(route, config);
    } else {
      return await axios.get(this.baseUrl + route, config);
    }
  };

  deleteData = async (route: string) => {
    return await axios.delete(this.baseUrl + route, this.axiosConfig);
  };

  getDataByKey = async (route: string, key: any) => {
    return await axios.get(
      this.baseUrl + route + "?key=" + key,
      this.axiosConfig
    );
  };
}
