import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCab,
  getCabCat,
  getCabCatById,
} from "./../../features/location/locationCab";
import { Link } from "react-router-dom";

function LocationArea(props: any) {
  const categorie = useSelector((state: any) => state.location.cat);
  const location = useSelector((state: any) => state.location.cab);
  const links = useSelector((state: any) => state.location.links);

  const dispatch = useDispatch();

  const [active, setActive] = useState<any>(-1);

  useEffect(() => {
    dispatch<any>(getCab(null));

    dispatch<any>(getCabCat());
    console.log("location cate");
    console.log(categorie);
    console.log(location);
    console.log("location cate");
  }, [dispatch]);

  return (
    <div>
      {" "}
      <div className="taxi-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Our Taxi</span>
                <h2 className="site-title">Let's Check Available Taxi</h2>
                <div className="heading-divider"></div>
              </div>
            </div>
          </div>{" "}
          <div className="filter-controls">
            <ul className="filter-btns">
              <li
                className={active === -1 ? "active" : ""}
                onClick={() => {
                  setActive(-1);
                  dispatch<any>(getCab(null));
                }}
              >
                All Taxi
              </li>
              {categorie.map((item: any) => (
                <>
                  <li
                    className={active === item.id ? "active" : ""}
                    onClick={() => {
                      setActive(item.id);
                      dispatch<any>(getCabCatById(item.id));
                    }}
                  >
                    {item.libelle}
                  </li>
                </>
              ))}
            </ul>
          </div>
          <div className="row filter-box">
            {location.map((item: any) => (
              <>
                {" "}
                <div
                  className={`col-md-6 col-lg-4 filter-item ${item.id_categorie}`}
                >
                  <div className="taxi-item">
                    <div className="taxi-img">
                      <img
                        src={item.image}
                        height={700}
                        width={527}
                        style={{ width: "488px", height: "300px" }}
                        alt=""
                      />
                    </div>
                    <div className="taxi-content">
                      <div className="taxi-head">
                        <h4>{item.libelle}</h4>
                        <span>
                          {item.modele} ( {item.matricule})
                        </span>
                      </div>
                      <div className="taxi-feature">
                        <ul>
                          <li>
                            <i className="fa fa-car-side-bolt"></i> Automatique:
                            <span>{item.is_auto ? "Oui" : "Non"}</span>
                          </li>
                          <li>
                            <i className="fa fa-car-side-bolt"></i> Climatisée:
                            <span>{item.is_clim ? "Oui" : "Non"}</span>
                          </li>
                          <li>
                            <i className="fa fa-person-seat"></i> Passengers:
                            <span>{item.nb_place}</span>
                          </li>
                          <li>
                            <i className="fa fa-suitcase-rolling"></i>Dans la
                            ville :<span>{item.montant} Fcfa</span>
                          </li>
                          <li>
                            <i className="fa fa-heat"></i>Montant hors ville:
                            <span>
                              {" "}
                              {item.montant_hors_ville == null ? (
                                <span>N/A</span>
                              ) : (
                                item.montant_hors_ville
                              )}{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <a href="#" className="theme-btn">
                        Commander<i className="fa fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="pagination-area">
            <div aria-label="Page navigation example">
              <ul className="pagination">
                {!props.isPaginate ? (
                  <>
                    {" "}
                    <li className="page-item">
                      <Link
                        className="theme-btn"
                        to="/location"
                        aria-label="Previous"
                      >
                        <span aria-hidden="true">Loard more</span>
                      </Link>{" "}
                    </li>
                  </>
                ) : (
                  <>
                    {links.map((item: any, index: number) => {
                      if (item.url !== null) {
                        return (
                          <>
                            <li
                              className={`page-item ${
                                item.active ? "active" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                onClick={() => {
                                  console.log(item.url);

                                  dispatch<any>(getCab(item.url));
                                }}
                              >
                                {item.label}
                              </a>
                            </li>
                          </>
                        );
                      }
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationArea;
