import React from "react";

import { HashRouter, Route, Routes, Navigate } from "react-router-dom";

import ClientRoute from "./routes/client-route/client-route";
import AgenceDash from "./routes/client-route/agence-dash";
import AuthDash from "./routes/auth/auth-dash";

const isAuthenticated = localStorage.getItem("hashkey")===null?false:true;

const PrivateRoute = ({ children }: any) => {
 
  
  return isAuthenticated ? children : <Navigate to="/auth/login" />;
};
const PrivateRouteNoAuth = ({ children }: any) => {
  return isAuthenticated ? <Navigate to="/agence/home" /> : children;
};

function App() {
  React.useEffect(() => {}, []);

  return (
    <HashRouter>
      <Routes>
        <Route path="/*" element={<ClientRoute />} />
        <Route
          path="/agence/*"
          element={
            <PrivateRoute>
              <AgenceDash />
            </PrivateRoute>
          }
        />
        <Route
          path="/auth/*"
          element={
            <PrivateRouteNoAuth>
              {" "}
              <AuthDash />
            </PrivateRouteNoAuth>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
