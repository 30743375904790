
import React from 'react';
import './banner.css';

interface Banner {
    title: string;
    subtitle: string;
    image: string;
    className?: string;
}

function BuildBanner(params: Banner) {
    const bannerClasses = `bg-primary ${params.className || ''}`;

    return (
        <>
            <div className={bannerClasses} id={params.title}>
                <div className="container">
                    <div className="row align-items-end mt-200">
                        <div className="col-lg-8 index1">
                            <h1
                                className="hero-title text-white display-4 text-bold text-7xl"
                                data-animation="fadeInRight"
                                data-delay=".50s"
                            >
                                {params.title}
                            </h1>
                            <p className="mt-4 text-white">
                                {params.subtitle}.</p>
                        </div>
                        <div className="float-img">
                            <img src="./assets/img/lumiere.png" />
                        </div>
                    </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,256L80,250.7C160,245,320,235,480,245.3C640,256,800,288,960,282.7C1120,277,1280,235,1360,213.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
            </div>

        </>
    );
}

export default BuildBanner;