import React, { useEffect, useState } from "react";
import SideMenu from "../../components/agence/side-menu";
import { useSelector, useDispatch } from "react-redux";
import { getUserData, getUserDataList } from "../../features/auth/agence";
import moment from "moment";

function BookingList() {
  const dispatch = useDispatch();
  const useinfo = useSelector((state: any) => state.user.data);
  const locationinfo = useSelector((state: any) => state.user.locationList);
  const links = useSelector((state: any) => state.user.links);
  const [detail, SetDetail] = useState<boolean>(false);
  const [detailData, SetDetailData] = useState<any>({});

  const SetData = (data: any) => {
    console.log(data);

    SetDetailData(data);
    SetDetail(!detail);
  };

  useEffect(() => {
    dispatch<any>(getUserData());
    dispatch<any>(getUserDataList(null));
    console.log("locationinfo");
    console.log(locationinfo);
  }, [dispatch]);
  return (
    <div>
      {" "}
      <main className="main">
        <div className="user-profile py-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu userData={useinfo} />
              </div>
              <div className="col-lg-9">
                <div className="user-profile-wrapper">
                  <div className="user-profile-wrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="user-profile-card">
                          {detail ? (
                            <>
                              {" "}
                              <div className="user-profile-card-header">
                                <h4 className="user-profile-card-title">
                                  Détail de la location
                                </h4>
                                <a
                                  onClick={() => SetDetail(!detail)}
                                  className="btn btn-outline-secondary btn-sm rounded-2"
                                  data-bs-toggle="tooltip"
                                  title="Close"
                                >
                                  RETOUR
                                </a>
                              </div>
                              <>
                                <h3>Informations</h3>
                                <dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Adresse client:{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.utilisateur.telephone} -{" "}
                                      {detailData.utilisateur.email}
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Nom client:{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.utilisateur.nom}{" "}
                                      {detailData.utilisateur.prenom}
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Date solicitation:{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.date_solicitation}
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Heure solicitation:{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.heure_solicitation}
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Date retour:{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.date_retour}
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Heure retour:{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.heure_retour}
                                    </dd>
                                  </dl>
                                </dl>

                                <br />
                              </>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className="user-profile-card-header">
                                <h4 className="user-profile-card-title">
                                  Me locations
                                </h4>
                                <div className="user-profile-card-header-right">
                                  <div className="user-profile-search">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..." 
                                      />
                                      <i className="fa fa-search"></i>
                                    </div>
                                  </div>
                                  <a href="#" className="theme-btn">
                                    <span className="fas fa-taxi"></span>
                                    Rechercher
                                  </a>
                                </div>
                              </div>
                              <div className="table-responsive">
                                <table className="table text-nowrap">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Info</th>
                                      <th>Journey Date</th>
                                      <th>Drop Off Date</th>
                                      <th>Montant</th>
                                      <th>Payée</th>
                                      <th>Statust</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {locationinfo.map((item: any) => {
                                      return (
                                        <>
                                          {" "}
                                          <tr>
                                            <td>
                                              <div className="table-list-info">
                                                <a href="#">
                                                  <img
                                                    src={item.vehicule.image}
                                                    alt=""
                                                  />
                                                </a>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="table-list-info">
                                                <a href="#">
                                                  <div className="table-list-content">
                                                    <h6>
                                                      {item.vehicule.libelle}
                                                    </h6>
                                                    <span>
                                                      Matricule:{" "}
                                                      {item.vehicule.matricule}
                                                    </span>
                                                  </div>
                                                </a>
                                              </div>
                                            </td>
                                            <td>
                                              <span>
                                                {moment(
                                                  item.date_solicitation
                                                ).format("D MMMM, YYYY")}
                                              </span>
                                              <p>{item.heure_solicitation}</p>
                                            </td>{" "}
                                            <td>
                                              <span>
                                                {moment(
                                                  item.date_retour
                                                ).format("D MMMM, YYYY")}
                                              </span>
                                              <p>{item.heure_retour}</p>
                                            </td>
                                            <td>{item.montant} Fcfa</td>
                                            <td>
                                              <span
                                                className={`badge  ${
                                                  item.is_paid
                                                    ? "badge-primary"
                                                    : "badge-danger"
                                                }`}
                                              >
                                                {item.is_paid ? "Yes" : "Non"}
                                              </span>
                                            </td>{" "}
                                            <td>
                                              <span
                                                className={`badge ${
                                                  item.status === "EN COURS"
                                                    ? "badge-primary"
                                                    : item.status ===
                                                      "CONFIRMEE"
                                                    ? "badge-info"
                                                    : item.status === "TERMINEE"
                                                    ? "badge-success"
                                                    : item.status === "ANNULLEE"
                                                    ? "badge-danger"
                                                    : "badge-primary"
                                                }`}
                                              >
                                                {item.status === "EN COURS"
                                                  ? "EN COURS"
                                                  : item.status === "CONFIRMEE"
                                                  ? "CONFIRMEE"
                                                  : item.status === "TERMINEE"
                                                  ? "TERMINEE"
                                                  : item.status === "ANNULLEE"
                                                  ? "ANNULLEE"
                                                  : "EN ATTENTE"}
                                              </span>
                                            </td>
                                            <td>
                                              <a
                                                onClick={() => SetData(item)}
                                                className="btn btn-outline-secondary btn-sm rounded-2"
                                                data-bs-toggle="tooltip"
                                                title="Details"
                                              >
                                                <i className="far fa-eye"></i>
                                              </a>
                                            </td>
                                          </tr>{" "}
                                        </>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              <div className="pagination-area">
                                <div aria-label="Page navigation example">
                                  <ul className="pagination my-3">
                                    {links.map((item: any, index: number) => {
                                      if (item.url !== null) {
                                        return (
                                          <>
                                            <li
                                              className={`page-item ${
                                                item.active ? "active" : ""
                                              }`}
                                            >
                                              <a
                                                className="page-link"
                                                onClick={() => {
                                                  console.log(item.url);

                                                  dispatch<any>(
                                                    getUserDataList(item.url)
                                                  );
                                                }}
                                              >
                                                {item.label}
                                              </a>
                                            </li>
                                          </>
                                        );
                                      }
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default BookingList;
