import React, { useEffect, useState } from "react";
import SideMenu from "../../components/agence/side-menu";
import { useSelector, useDispatch } from "react-redux";
import { getUserData, getFlotteDataList } from "../../features/auth/agence";
import moment from "moment";

function FlotteList() {
  const dispatch = useDispatch();
  const useinfo = useSelector((state: any) => state.user.data);
  const flotte = useSelector((state: any) => state.user.flotteList);
  const [detail, SetDetail] = useState<boolean>(false);
  const [detailData, SetDetailData] = useState<any>({});

  const SetData = (data: any) => {
    console.log(data);
    SetDetailData(data);
    SetDetail(!detail);
  };

  useEffect(() => {
    dispatch<any>(getFlotteDataList());
    dispatch<any>(getUserData());
    console.log("flotte");
  }, [dispatch]);
  return (
    <div>
      {" "}
      <main className="main">
        <div className="user-profile py-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu userData={useinfo} />
              </div>
              <div className="col-lg-9">
                <div className="user-profile-wrapper">
                  <div className="user-profile-wrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="user-profile-card">
                          {detail ? (
                            <>
                              {" "}
                              <div className="user-profile-card-header">
                                <h4 className="user-profile-card-title">
                                  Détail Sur le vehicule
                                </h4>
                                <a
                                  onClick={() => SetDetail(!detail)}
                                  className="btn btn-outline-secondary btn-sm rounded-2"
                                  data-bs-toggle="tooltip"
                                  title="Close"
                                >
                                  RETOUR
                                </a>
                              </div>
                              <>
                              <h3>Vehicule</h3>
                                <dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Matricule:{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.vehicle.matricule} 
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Category :{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.vehicle.category.libelle}
                                    </dd>
                                  </dl>
                          
                                </dl>

                                <br />
                                <h3>Chauffeur</h3>
                                <dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Nom:{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                    {detailData.vehicle.driver.nom} {detailData.vehicle.driver.prenom} 
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Téléphone :{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.vehicle.driver.telephone}
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Email :{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7">
                                      {detailData.vehicle.driver.email}
                                    </dd>
                                  </dl>
                                  <dl className="row">
                                    <dt className="my-2 col-sm-5">
                                      Balance :{" "}
                                    </dt>
                                    <dd className="my-2 col-sm-7 text-primary">
                                      {detailData.vehicle.driver.balance} Fcfa
                                    </dd>
                                  </dl>
                          
                                </dl>

                                <br />
                                
                              </>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className="user-profile-card-header">
                                <h4 className="user-profile-card-title">
                                  Ma flotte
                                </h4>
                                <div className="user-profile-card-header-right">
                                  <div className="user-profile-search">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                      />
                                      <i className="fa fa-search"></i>
                                    </div>
                                  </div>
                                  <a href="#" className="theme-btn">
                                    <span className="fas fa-taxi"></span>
                                    Rechercher
                                  </a>
                                </div>
                              </div>
                              <div className="table-responsive">
                                <table className="table text-nowrap">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Image</th>
                                      <th>Photo</th>
                                      <th>Matricule</th>
                                      <th>Categorie</th>
                                      <th>Chauffeur</th>
                                      <th>Téléphone</th>
                                      <th>Email</th>
                                      <th>Solde</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    { flotte.map((item: any, index: number) => {
                                      return (
                                        <>
                                          {" "}
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                              <div className="table-list-info">
                                                <a href="#">
                                                  <img
                                                    src={item.vehicle.image}
                                                    alt=""
                                                  />
                                                </a>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="table-list-info">
                                                <a href="#">
                                                  <img
                                                    src={
                                                      item.vehicle.driver.photo
                                                    }
                                                    alt=""
                                                  />
                                                </a>
                                              </div>
                                            </td>
                                            <td>{item.vehicle.matricule}</td>
                                            <td>
                                              {item.vehicle.category.libelle}
                                            </td>
                                            <td>
                                              {item.vehicle.driver.nom}{" "}
                                              {item.vehicle.driver.prenom}
                                            </td>
                                            <td>
                                              {item.vehicle.driver.telephone}
                                            </td>
                                            <td>{item.vehicle.driver.email}</td>
                                            <td>
                                              {item.vehicle.driver.balance} Fcfa
                                            </td>
                                            <td>
                                              <a
                                                onClick={() => SetData(item)}
                                                className="btn btn-outline-secondary btn-sm rounded-2"
                                                data-bs-toggle="tooltip"
                                                title="Details"
                                              >
                                                <i className="far fa-eye"></i>
                                              </a>
                                            </td>
                                          </tr>{" "}
                                        </>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default FlotteList;
