import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from "swiper/modules";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

function SlideShow() {
  return (
    <>
      <div className="hero-section">
        <div className="hero-slider ">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            navigation
            autoplay={true}
            effect="fade"
          >
            <SwiperSlide>
              {" "}
              <div
                className="hero-single"
                style={{
                  backgroundImage: `url("./assets/img/banner02.jpg")`,
                }}
              >
                <div className="container">
                  <div className="row align-items-start">
                    <div className="col-md-12 col-lg-8">
                      <div className="hero-content text-start">
                        <h6
                          className="hero-sub-title"
                          data-animation="fadeInUp"
                          data-delay=".25s"
                        >
                          {/* OnGo où aujourd'hui ? */}
                        </h6>
                        <h1
                          className="hero-title text-7xl"
                          data-animation="fadeInRight"
                          data-delay=".50s"
                        >
                          Embarquez vers l'avenir de la mobilité avec ONGO.
                        </h1>
                        <div
                          className="hero-btn justify-content-start"
                          data-animation="fadeInUp"
                          data-delay="1s"
                        >
                          <p> Où chaque voyage devient une expérience inoubliable.</p>
                        </div>
                      </div>
                      <div className="float-img">
                        <img src="./assets/img/lumiere.png" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div
                className="hero-single"
                style={{
                  backgroundImage: `url("./assets/img/banner01.jpg")`,
                }}
              >
                <div className="container">
                  <div className="row align-items-start">
                    <div className="col-md-12 col-lg-8">
                      <div className="hero-content text-start">
                        <h6
                          className="hero-sub-title"
                          data-animation="fadeInUp"
                          data-delay=".25s"
                        >
                          {/* OnGo sûrement ! */}
                        </h6>
                        <h1
                          className="hero-title text-7xl"
                          data-animation="fadeInRight"
                          data-delay=".50s"
                        >
                          Plateforme numérique de transport urbain et inter-urbain
                        </h1>
                        <div
                          className="hero-btn justify-content-center"
                          data-animation="fadeInUp"
                          data-delay="1s"
                        >
                          <p>Révolutionnez vos déplacements au Cameroun avec notre application, offrant confort, sécurité et efficacité à chaque trajet.</p>
                        </div>
                      </div>

                      <div className="float-img">
                        <img src="./assets/img/lumiere.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>

        </div>
      </div>
    </>
  );
}

export default SlideShow;
