import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LocationService from "../../services/location/location";

// Asynchronous action creator using createAsyncThunk
export const getCab = createAsyncThunk("location/getCab", async (url:any=null) => {
  const location = await new LocationService().getLocation(url);
  console.log(location);
  console.log(location);
  return location;
});

/* export const getCab = createAsyncThunk('location/getCab', async () => {
  // Fetch data from an API or perform some asynchronous operation
  const data = await Api.getData("location/vehicule/all");
  
  return data.json();
}); */

export const getCabCat = createAsyncThunk("location/getCabCat", async () => {
  const location = await new LocationService().getLocationCategory();
  console.log(location);
  console.log(location);
  return location;
});

export const getCabCatById = createAsyncThunk(
  "location/getCabCatById",
  async (id: any) => {
    try {
      const location = await new LocationService().getLocationCabByCategory(id);
      console.log(location);
      return location;
    } catch (error) {
      console.error("Error fetching cab by category ID:", error);
      throw error;
    }
  }
);

export const locationCab = createSlice({
  name: "location",
  initialState: {
    value: 0,
    cab: [],
    cat: [],
    links:[],

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCab.pending, (state) => {
        // Handle pending state, e.g., showing a loading spinner
        state.cab = [];
      })
      .addCase(getCab.fulfilled, (state, action) => {
        // Handle fulfilled state, update the state with the fetched data
        console.log(" payload");
        console.log(action.payload.data.data);
        console.log(" payload");
        state.cab = action.payload.data.data;
        state.links = action.payload.data.links;
        
      })
      .addCase(getCab.rejected, (state, action) => {
        // Handle rejected state, e.g., show an error message
        state.cab = [];
      });
    builder
      .addCase(getCabCat.pending, (state) => {
        // Handle pending state, e.g., showing a loading spinner
        console.log("lodaing");
        state.cat = [];
      })
      .addCase(getCabCat.fulfilled, (state, action) => {
        // Handle fulfilled state, update the state with the fetched data
        console.log(" payload");
        console.log(action.payload.data);
        console.log(" payload");

        state.cat = action.payload.data;
      })
      .addCase(getCabCat.rejected, (state, action) => {
        console.log("rejected");
        console.log(action.error.message);
        // Handle rejected state, e.g., show an error message
        state.cat = [];
      });
    builder
      .addCase(getCabCatById.pending, (state) => {
        // Handle pending state, e.g., showing a loading spinner
        console.log("lodaing");
        state.cab = [];
      })
      .addCase(getCabCatById.fulfilled, (state, action) => {
        // Handle fulfilled state, update the state with the fetched data
        console.log(" payload");
        console.log(action.payload.data.data);
        console.log(" payload");

        state.cab = action.payload.data.data;
      })
      .addCase(getCabCatById.rejected, (state, action) => {
        console.log("rejected");
        console.log(action.error.message);
        // Handle rejected state, e.g., show an error message
        state.cab = [];
      });

      
  },
});

export default locationCab.reducer;
