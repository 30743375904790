import ApiService from "../api-service";

export default class LocationService {
  getLocation = async (url:any) => {
    const Api = new ApiService();
    const data = await Api.getData(url==null?"location/vehicule/all":url);
    return data.data;
  };

  getLocationCategory = async () => {
    const Api = new ApiService();
    const data = await Api.getData("location/category/all");
    return data.data;
  };

  getLocationCabByCategory = async (id: any) => {
    const Api = new ApiService();
    const data = await Api.getData("location/vehicule/cat/"+id);
    return data.data;
  };
}
