import React from "react";
import { Route, Routes } from "react-router-dom";
import Layouts from "../../layouts/Layouts";
import Home from "../../views/agences";
import Login from "../../views/auth/login";
function AuthDash() {
  return (
    <Routes>
    <Route path="/"  element={<Layouts />}>
      <Route path="/login"   element={<Login />} />
    </Route>
  </Routes>
  )
}

export default AuthDash