import React from "react";
import LocationArea from "../../components/home/location-area";

function LocationList() {
  return (
    <div>
      {" "}
      <main className="main">
        <LocationArea isPaginate={true}/>
      </main>
    </div>
  );
}

export default LocationList;
