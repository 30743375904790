import React from "react";
import { Link } from "react-router-dom";
import BuildBanner from "../../components/banner/banner";
export default function CompanyPage() {

    return (
        <>
            <div className="page-container">
                <BuildBanner image="" subtitle="Vous possédez une flotte de véhicules ? Vous êtes une entreprise spécialisée dans la location de véhicules ? Dans ce cas, Ongo est le partenaire idéal pour vous." title="Ongo que vous connaissez, repensé pour les entreprises" className="company-banner banner-gradient"></BuildBanner>

                <div className="bg-white">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="mb-20">Rejoignez Ongo avec votre flotte et gagnez plus</h1>
                                <p className="mb-50">
                                Optez pour l'excellence avec ONGO : Maximisez vos gains en rejoignant notre réseau avec votre flotte. En tant que partenaire ONGO, vous bénéficiez d'un tableau de bord intuitif pour gérer efficacement vos actifs et développer prospèrement votre activité de transport. </p>
                            </div>
                            <div className="col-lg-6 text-center">
                                <img className="" src="./assets/img/company-img.png" alt="" />
                            </div>
                        </div>
                        <hr className="mt-5" />

                        <div className="row pt-50 gy-3">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-1.png" width={100} alt="" />
                                <h4 className="mt-4">Boostez vos revenus</h4>
                                <p className="mt-2">Une vaste communauté de clients signifie une augmentation du nombre de commandes quotidiennes et, par conséquent, une augmentation de vos revenus.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-2.png" width={100} alt="" />
                                <h4 className="mt-4">Gérez vos ressources</h4>
                                <p className="mt-2">Gérez efficacement vos véhicules, vos chauffeurs et leur statut en temps réel.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-3.png" width={100} alt="" />
                                <h4 className="mt-4">Suivez leurs performances</h4>
                                <p className="mt-2">Un accès facile aux factures, aux paiements et aux trajets effectués par chaque chauffeur.</p>
                            </div>
                        </div>
                        <hr className="mt-50" />
                        <div className="container text-center mb-50">
                            <img className="rotate" src="./assets/img/ongo-fleet.png" alt="" />
                            <p className="col-5 mx-auto text-center">Rejoignez Ongo avec votre flotte et gagnez plus. <br /> En tant que propriétaire de flotte et partenaire Ongo, vous pouvez gérer vos actifs à partir d'un tableau de bord facile à utiliser et développer votre activité de transport</p>
                        </div>

                    </div>
                </div>

                <div className="bg-grey py-100">
                    <div className="container">
                        <h1 className="mb-20 text-center">Développez votre activité de transport avec Ongo</h1>
                        <div className="row pt-50 gy-3">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-1.png" width={100} className="rounded-circle" alt="" />
                                <h4 className="mt-4">Créez votre compte Ongo</h4>
                                <p className="mt-2">Il vous suffit de télécharger l'application, de créer un compte, puis d'envoyer votre demande pour devenir chauffeur. Cette démarche ne vous prendra que quelques minutes</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-2.png" width={100} className="rounded-circle" alt="" />
                                <h4 className="mt-4">Prenez rendez-vous avec le service support</h4>
                                <p className="mt-2">Une fois que vous avez soumis votre demande via l'application, notre équipe de support vous contactera pour planifier un rendez-vous de formation au service.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-3.png" width={100} className="rounded-circle" alt="" />
                                <h4 className="mt-4">Activation du compte</h4>
                                <p className="mt-2">Une fois la formation achevée avec succès, votre compte Entreprise sera activé, vous permettant de commencer à recevoir des commandes et à augmenter vos revenus.</p>
                            </div>
                        </div>
                        <div className="rounded bg-white mt-50 p-50 text-center">
                            <h2 className="text-center mb-50 col-8 mx-auto">Surveillez l'activité de votre flotte et gérez vos revenus à partir d'un tableau de bord Ongo.</h2>
                            <img src="https://i.pinimg.com/564x/fa/9f/d4/fa9fd4b8b01b25d87912fded842f2f0e.jpg" alt="" />
                        </div>

                    </div>
                </div>

                {/* <div className="bg-white py-100">
                    <div className="container">
                        <div className="mt-0">
                            <h1>Service support </h1>
                            <p>besion d'aide ? nous sommes là pour vous</p>
                            <div className="row pt-50 gy-3">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <img className="size-50" src="https://www.uber-assets.com/image/upload/q_auto:eco,c_fill,w_96,h_96/v1535523136/assets/a7/c590ac-242e-47eb-86cb-90db7712d44f/original/Community-Guidelines.svg" alt="" />
                                    <h4 className="mt-2">Découvrez le blog des Entreprises</h4>
                                    <p className="mt-2">Retrouvez plusieurs informations et conseils sur le métier du transport.</p>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <img className="size-50" src="https://www.uber-assets.com/image/upload/q_auto:eco,c_fill,w_96,h_96/v1535523101/assets/22/bdf7a1-f49f-47c0-a59e-3e6bc274b6f2/original/24_7-Support.svg" alt="" />
                                    <h4 className="mt-2">Discutez en ligne avec un conseiller</h4>
                                    <p className="mt-2">Nos conseillers peuvent répondre à toutes vos questions.</p>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <img className="size-50" src="https://www.uber-assets.com/image/upload/q_auto:eco,c_fill,w_96,h_96/v1536104955/assets/84/1767a7-75d0-4a9f-9f1a-46fc6f499ac1/original/911-Assistance-Button.svg" alt="" />
                                    <h4 className="mt-2">Besoin d'aide ?</h4>
                                    <p className="mt-2">Vous avez une question sur les démarches à suivre ? Nous sommes là pour vous répondre.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="mt-100  mb-100 w-100">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-lg-6">
                                <h1>
                                    Augmentez vos revenus de manière exponentielle en devenant partenaire Ongo.</h1>
                                <p className="mt-4">Le début de l'inscription ne vous prendra que quelques minutes !</p>

                                <div className="nav-right-btn mt-50">
                                    <Link to="/becomedriver" className="theme-btn">
                                        <span className="fa fa-taxi"></span>Télécharger l'application
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <img className="rounded" src="./assets/img/banner2.jpg" alt="" />
                            </div>

                        </div>
                    </div>

                </div>

                {/* <div className="bg-white py-100">
                    <div className="container">
                        <h1 className="mb-30">Questions fréquentes des chauffeurs</h1>
                        <div className="col-lg-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Puis-je conduire avec OnGo dans ma ville ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="heading1"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>OnGo est disponible dans des centaines de villes du cameroun. Consultez nos pages Villes pour voir si nous sommes disponibles pour commencer à conduire et augmenter vos revenus chez vous.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                        >
                                            Comment puis-je m'inscrire pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Augmentez vos revenus avec OnGo facilement ! Renseignez vos informations ci-dessous, téléchargez tous les documents requis et finalisez votre inscription dans notre Espace Partenaires. Une fois tout cela réalisé, vous serez prêt à augmenter vos revenus selon vos propres horaires !</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTree">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTree"
                                            aria-expanded="true"
                                            aria-controls="collapseTree"
                                        >
                                            Ai-je besoin d'une voiture pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTree"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Oui, mais si vous ne possédez pas de voiture, pas d'inquiétude — d’autres options sont disponibles. Nos équipes locales peuvent vous aider à trouver des flottes partenaires ou des sociétés de location qui offrent des tarifs compétitifs sur les véhicules que nous acceptons.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFor">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFor"
                                            aria-expanded="true"
                                            aria-controls="collapseFor"
                                        >
                                            Ai-je besoin d'un smartphone pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFor"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFor"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Vous devez disposer d'un smartphone fonctionnant au moins sous Android 9.0 ou iOS 14. Si vous n'avez pas de smartphone, notre équipe peut vous conseiller sur un téléphone Android économique dont vous aurez besoin pour utiliser l'application OnGo Driver.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="true"
                                            aria-controls="collapseFive"
                                        >
                                            Peut-on augmenter ses revenus avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>L’avantage de conduire sur la plate-forme OnGo est que vous pouvez gagner de l’argent selon votre emploi du temps, ce qui en fait l’activité complémentaire idéale. Il n'y a pas de minimum d'heures, conduisez toute la semaine ou seulement le soir ou le week-end. Gagnez un revenu supplémentaire chaque fois que vous en avez besoin. Tu es le patron!</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSixe">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSixe"
                                            aria-expanded="true"
                                            aria-controls="collapseSixe"
                                        >
                                            Comment suis-je payé pour mes courses réalisées sur OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSixe"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingSixe"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Notre cycle de paiement s’étend du lundi minuit au dimanche suivant à 23:59, après quoi vous recevrez un relevé de solde. Vous recevrez vos paiements chaque semaine — pas besoin d'attendre la fin du mois.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div> */}

            </div>
        </>
    )
};