import React from "react";
import { Route, Routes } from "react-router-dom";
import Layouts from "../../layouts/Layouts";
import Home from "../../views/home/home";
import ErrorPage from "../../views/error-page/error-page";
import LocationList from "../../views/home/location";
import ContactUs from "../../views/contact-us/contact-us";
import BecomeDriver from "../../views/become-driver/become-driver";
import DriverPage from "../../views/driver/driver_page";
import ClientPage from "../../views/clients/client";
import CompanyPage from "../../views/company/company_page";
import Privacy from "../../views/privacy";
import Statistique from "../../views/statistique/statistique";
import AccountDeletion from "../../views/account-deletion";
function ClientRoute() {
  return (
    <Routes>
      <Route path="/" element={<Layouts />}>
        <Route path="/"  errorElement={<ErrorPage />} element={<Home />} />
        <Route path="/location"  errorElement={<ErrorPage />} element={<LocationList />} />
        <Route path="/contactus"  errorElement={<ErrorPage />} element={<ContactUs />} />
        <Route path="/becomedriver"  errorElement={<ErrorPage />} element={<BecomeDriver />} />
        <Route path="/driver"  errorElement={<ErrorPage />} element={<DriverPage />} />
        <Route path="/client"  errorElement={<ErrorPage />} element={<ClientPage />} />
        <Route path="/company"  errorElement={<ErrorPage />} element={<CompanyPage />} />
        <Route path="/statistique"  errorElement={<ErrorPage />} element={<Statistique />} />
        <Route path="/privacy"  errorElement={<ErrorPage />} element={<Privacy />} />
        <Route path="/account-deletion"  errorElement={<ErrorPage />} element={<AccountDeletion />} />
      
      </Route>
    </Routes>
  );
}

export default ClientRoute;
