import React from "react";
import { Link } from "react-router-dom";
export default function DriverPage() {

    return (
        <>
            <div className="page-container">
                <div className="driver-banner bg-primary">
                    <div className="container pt-200">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1
                                    className="hero-title text-white display-4 text-bold"
                                    data-animation="fadeInRight"
                                    data-delay=".50s"
                                >
                                    Augmentez vos revenus en devenant chauffeur ONGO
                                </h1>
                                <p className="mt-4 text-white">Générez des revenus selon votre emploi du temps.</p>
                                {/* <h1 className="text-white display-4 font-weight-bold">Conduisez quand vous voulez, générez des revenus sur mesure</h1> */}
                            </div>
                            <div className="col-lg-6">
                                <img src="https://www.seremo.com/assets/images/10.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,256L80,250.7C160,245,320,235,480,245.3C640,256,800,288,960,282.7C1120,277,1280,235,1360,213.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
                </div>

                <div className="bg-white">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="mb-20">Pourquoi devenir partenaire chauffeur ONGo ?</h1>
                                <p>Que vous souhaitiez conduire de manière occasionnelle pendant quelques heures ou générer des revenus de manière régulière, ONGO vous offre la flexibilité de choisir vos heures de conduite.</p>
                            </div>
                            <div className="col-lg-6">
                                <img className="mt-50" src="./assets/img/rent.jpg" width={500} alt="" />
                            </div>
                        </div>
                        <hr className="mt-5" />

                        <div className="row pt-50 mb-50 gy-3">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/done.png" width={30} alt="" />
                                <h4 className="mt-2">Plus de courses, plus de gains</h4>
                                <p className="mt-2"> Nos passagers vous enverront de nombreuses demandes de courses. Connectez-vous à l’application pour les réaliser et gagnez de l’argent en plus pour chaque course terminée.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/done.png" width={30} alt="" />
                                <h4 className="mt-2">Planifiez vos heures de travail</h4>
                                <p className="mt-2">Travaillez selon votre convenance. De jour? De nuit? En semaine? Le week-end? À plein temps? Quelques fois? Travaillez aussi longtemps et aussi souvent que vous le souhaitez.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/done.png" width={30} alt="" />
                                <h4 className="mt-2">Vous êtes payé en temps réel</h4>
                                <p className="mt-2">Pas besoin de vous inquiéter: votre argent est sécurisé et il vous est transféré à la fin de chaque course.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-grey py-100">
                    <div className="container">
                        <h1 className="mb-20 text-center">Devenir conducteur professionnel</h1>
                        <div className="row pt-50 gy-3">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-1.png" width={100} className="rounded-circle" alt="" />
                                <h4 className="mt-4">Créez votre compte chauffeur</h4>
                                <p className="mt-2">Il vous suffit de télécharger l'application, de créer un compte, puis d'envoyer votre demande pour devenir chauffeur. Cette démarche ne vous prendra que quelques minutes.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-2.png" width={100} className="rounded-circle" alt="" />
                                <h4 className="mt-4">Prenez rendez-vous avec le service support</h4>
                                <p className="mt-2">Après avoir soumis votre demande via l'application, notre équipe de support vous contactera pour planifier un rendez-vous pour la formation chauffeur.</p>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <img src="./assets/img/count-3.png" width={100} className="rounded-circle" alt="" />
                                <h4 className="mt-4">Activation du compte</h4>
                                <p className="mt-2">Une fois la formation réussie, votre compte chauffeur sera activé, vous permettant de recevoir des commandes et d'augmenter vos revenus.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-area py-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h1 className="mb-20 mt-20">Télécharger gratuitement la première application de transport urbain et inter-urbain 100% camerounaise</h1>
                                <p className="mb-50">Découvrez une expérience de transport exceptionnelle avec Ongo : Téléchargez l'application dès maintenant sur l'Apple Store et le Play Store, elle est à portée de main !</p>
                                <div className="download-btn">
                                    <a href="https://play.google.com/store/search?q=ongo&c=apps&gl=FR">
                                        <i className="fab fa-google-play"></i>
                                        <div className="download-btn-content">
                                            {/*  <!--  <span>Get It On</span> --> */}
                                            <strong>Google Play</strong>
                                        </div>
                                    </a>
                                    <a href="https://apps.apple.com/fr/app/ongo/id1625930293">
                                        <i className="fab fa-app-store"></i>
                                        <div className="download-btn-content">
                                            {/*    <!--  <span>Get It On</span> --> */}
                                            <strong>App Store</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 text-center">
                                <img className="" src="./assets/img/company-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app-banner-driver py-120 mt-100 w-100">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-lg-6">
                                <h1>Soyez votre propre patron. Commencez à conduire pour ONGO et à augmentez vos revenus !</h1>
                                <p className="mt-4">Le début de l'inscription ne vous prendra que quelques minutes !</p>

                                <div className="nav-right-btn mt-50">
                                    <Link to="/becomedriver" className="theme-btn">
                                        <span className="fa fa-taxi"></span>Télécharger l'application
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <img className="rounded" src="./assets/img/banner2.jpg" alt="" />
                            </div>

                        </div>
                    </div>

                </div>

                {/* <div className="bg-white py-100">
                    <div className="container">
                        <h1 className="mb-30">Questions fréquentes des chauffeurs</h1>
                        <div className="col-lg-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Puis-je conduire avec OnGo dans ma ville ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="heading1"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>OnGo est disponible dans des centaines de villes du cameroun. Consultez nos pages Villes pour voir si nous sommes disponibles pour commencer à conduire et augmenter vos revenus chez vous.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                        >
                                            Comment puis-je m'inscrire pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Augmentez vos revenus avec OnGo facilement ! Renseignez vos informations ci-dessous, téléchargez tous les documents requis et finalisez votre inscription dans notre Espace Partenaires. Une fois tout cela réalisé, vous serez prêt à augmenter vos revenus selon vos propres horaires !</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTree">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTree"
                                            aria-expanded="true"
                                            aria-controls="collapseTree"
                                        >
                                            Ai-je besoin d'une voiture pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTree"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Oui, mais si vous ne possédez pas de voiture, pas d'inquiétude — d’autres options sont disponibles. Nos équipes locales peuvent vous aider à trouver des flottes partenaires ou des sociétés de location qui offrent des tarifs compétitifs sur les véhicules que nous acceptons.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFor">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFor"
                                            aria-expanded="true"
                                            aria-controls="collapseFor"
                                        >
                                            Ai-je besoin d'un smartphone pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFor"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFor"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Vous devez disposer d'un smartphone fonctionnant au moins sous Android 9.0 ou iOS 14. Si vous n'avez pas de smartphone, notre équipe peut vous conseiller sur un téléphone Android économique dont vous aurez besoin pour utiliser l'application OnGo Driver.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="true"
                                            aria-controls="collapseFive"
                                        >
                                            Peut-on augmenter ses revenus avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>L’avantage de conduire sur la plate-forme OnGo est que vous pouvez gagner de l’argent selon votre emploi du temps, ce qui en fait l’activité complémentaire idéale. Il n'y a pas de minimum d'heures, conduisez toute la semaine ou seulement le soir ou le week-end. Gagnez un revenu supplémentaire chaque fois que vous en avez besoin. Tu es le patron!</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSixe">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSixe"
                                            aria-expanded="true"
                                            aria-controls="collapseSixe"
                                        >
                                            Comment suis-je payé pour mes courses réalisées sur OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSixe"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingSixe"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Notre cycle de paiement s’étend du lundi minuit au dimanche suivant à 23:59, après quoi vous recevrez un relevé de solde. Vous recevrez vos paiements chaque semaine — pas besoin d'attendre la fin du mois.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div> */}

            </div>
        </>
    )
};