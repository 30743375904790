import React from "react";
import { Route, Routes } from "react-router-dom";
import Layouts from "../../layouts/Layouts";
import Agence from "../../views/agences";
import Profil from "../../views/agences/profil";
import BookingList from "../../views/agences/booking";
import FlotteList from "../../views/agences/FlotteList";

function AgenceDash() {
  return (
    <Routes>
    <Route path="/"  element={<Layouts />}>
      <Route path="/home"   element={<Profil />} />
      <Route path="/profil"   element={<Agence />} />
      <Route path="/booking"   element={<BookingList />} />
      <Route path="/flotte"   element={<FlotteList />} />
  {/*     <Route path="/cancel/booking"   element={<Agence />} />
      <Route path="/payment"   element={<Agence />} />
      <Route path="/settings"   element={<Agence />} /> */}
    </Route>
  </Routes>
  )
}

export default AgenceDash