
import ApiService from "../api-service";

export default class FlotteService {
  
  FlotteDataList = async () => {
    const Api = new ApiService();
    
    var token = localStorage.getItem("hashkey");
    var user=localStorage.getItem("isAuthenticated");
    var userdata =user ? JSON.parse(user) : null; 
    console.log("utilisateur/partner-detail?user_id="+userdata.id);

    const data = await Api.getDataWithToken("utilisateur/partner-detail?user_id="+userdata.id,token);
 
    console.log(data);
    
    return data.data;
  };
}
