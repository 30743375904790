import BuildBanner from "../components/banner/banner";

export default function Privacy() {

    return (
        <div className="driver-banner">
            <div className="driver-banner bg-primary">
                <div className="container pt-200 pb-100">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h1
                                className="hero-title text-white text-center display-4 text-bold"
                                data-animation="fadeInRight"
                                data-delay=".50s"
                            >
                                Politique de confidentialité
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-20 mb-50">
                <p className="mb-3">Chez OnGo, nous accordons une grande importance à la protection de la vie privée de nos utilisateurs. Nous comprenons l'importance de protéger les informations personnelles que vous partagez avec nous lorsque vous utilisez notre application. Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos données personnelles lorsque vous utilisez notre application ONGO.</p>
                <h4 className="mb-3">Collecte d'informations personnelles:</h4>
                <p className="mb-3">Nous collectons des informations personnelles telles que votre nom, votre adresse e-mail, votre numéro de téléphone, votre géolocalisation, votre historique de trajet et votre mode de paiement lorsque vous utilisez notre application ONGO. Nous collectons également des informations concernant votre appareil mobile, votre adresse IP et les données de navigation lorsque vous utilisez notre application. Ces informations sont collectées afin de vous offrir une expérience de voyage sûre, rapide et personnalisée.</p>
                <h4 className="mb-3">Utilisation des informations personnelles:</h4>
                <p className="mb-3">Les informations personnelles collectées par ONGO sont utilisées pour faciliter votre utilisation de notre application. Nous utilisons ces informations pour communiquer avec vous, pour traiter vos paiements, pour optimiser votre expérience utilisateur et pour personnaliser les offres et les promotions que nous vous proposons. Nous utilisons également les informations collectées pour améliorer notre application OnGo et pour effectuer des analyses statistiques.</p>
                <h4 className="mb-3">Partage des informations personnelles:</h4>
                <p className="mb-3">Nous partageons certaines de vos informations personnelles avec nos partenaires de service tels que les chauffeurs, les sociétés de traitement des paiements et les fournisseurs de services de géolocalisation. Nous partageons également les informations personnelles avec les autorités gouvernementales si nécessaire pour se conformer à la loi.</p>
                <h4 className="mb-3">Protection des informations personnelles:</h4>
                <p className="mb-3">Chez OnGo, nous prenons la protection des informations personnelles très au sérieux. Nous avons mis en place des mesures de sécurité physiques, techniques et administratives pour protéger les informations personnelles contre la perte, l'utilisation abusive et l'accès non autorisé.</p>
                <h4 className="mb-3">Modifications de la politique de confidentialité:</h4>
                <p className="mb-3">Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les changements apportés à cette politique de confidentialité seront publiés sur notre site web.</p>
                <p className="mb-3">Contactez-nous: Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité ou la façon dont nous collectons, utilisons et protégeons vos informations personnelles, n'hésitez pas à nous contacter à l'adresse suivante : contact@ongo237.com.</p>
                <p className="mb-3">Nous vous remercions de faire confiance à ONGO pour vos déplacements. Nous nous engageons à protéger vos informations personnelles et à vous offrir une expérience de voyage agréable et sécurisée. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter.</p>
            </div>
        </div>
    )
}