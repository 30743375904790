import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap, Power3 } from "gsap";

function HeaderNav() {

  return (
    <div>

      <div className="main-navigation">
        <nav className="navbar navbar-expand-lg">
          <div className="container position-relative">
            <a className="navbar-brand" href="">
              <img src="./assets/img/logo-trs.png" alt="logo" />
            </a>
            <div className="mobile-menu-right">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-mobile-icon">
                  <i className="fa fa-bars"></i>
                </span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="main_nav">
              <ul className="navbar-nav">

                <li className="nav-item">
                  <Link className="nav-link" to="/client">
                    Passager
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/driver">
                    Chauffeur
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/company">
                    Entreprise
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/contactus">
                    Nous Contacter
                  </Link>
                </li>
              </ul>
              <div className="nav-right">
                <div className="nav-right-btn mt-2">
                  <Link to="/driver" className="theme-btn">
                    <span className="fa fa-taxi"></span>Devenir chauffeur
                  </Link>
                </div>

                <div className="nav-right-btn mt-2k nav-item">
                  {!localStorage.getItem("hashkey") ? <Link to="/auth/login" className="nav-link">
                    <i className="fa fa-arrow-right-to-bracket"></i> Me connecter
                  </Link> : <Link to="/agence/home">
                    <i className="fa fa-arrow-right-to-bracket"></i> Mon compte
                  </Link>}
                </div>
              </div>
            </div>

            <div className="search-area">
              <form action="#">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type Keyword..."
                  />
                  <button type="submit" className="search-icon-btn">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </div> 
  );
}

export default HeaderNav;
