import ApiService from "../api-service";

export default class AgenceService {
  userDataAgence = async () => {
    const Api = new ApiService();
    var token = localStorage.getItem("hashkey");
   
    
    const data = await Api.getDataWithToken("v2/utilisateur/get-auth-user",token);

    
    return data.data;
  };
  userDataAgenceList = async (url:any) => {
    const Api = new ApiService();
    console.log(url===null?"location/reservation/driver/meslocation":url);
    
    var token = localStorage.getItem("hashkey"); 
    const data = await Api.getDataWithToken(url===null?"location/reservation/driver/meslocation":url,token);
    return data.data;
  };
}
