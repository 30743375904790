import React from "react";
import { Link } from "react-router-dom";
import "./client.css";
import BuildBanner from "../../components/banner/banner";
export default function ClientPage() {

    return (
        <>
            <div className="page-container">
                <BuildBanner image="" subtitle="Transformez vos déplacements en expériences fluides, sécurisées et personnalisées." title="Explorez une nouvelle ère de facilité et d'efficacité avec notre application" className="client-banner banner-gradient"></BuildBanner>
                <div className="pt-20 pb-20">
                    <div className="container">
                        <h1 className="text-center mb-100">Un service de transport idéal  pour un déplacement sans stress ! Avec ONGO, on go sûrement</h1>
                        <div className="row nopadding">
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-grey">
                                <div className="list-items-img ">
                                    <h4 className="mt-2">Service Client</h4>
                                    <p className="mt-2">Notre équipe de support est constamment à votre disposition. N'hésitez pas à la contacter directement depuis l'application Ongo ou par téléphone.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-secondary">
                                <div className="list-items-img">
                                    <h4 className="mt-2 text-white">Evaluation du service</h4>
                                    <p className="mt-2">Les évaluations nous servent à surveiller le comportement de nos chauffeurs partenaires et à prendre des mesures contre ceux qui enfreignent nos conditions d'utilisation.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-secondary">
                                <div className="list-items-img">
                                    <h4 className="mt-2 text-white">Service d'urgence</h4>
                                    <p className="mt-2">Utilisez notre bouton d'assistance d'urgence intégré à l'application pour alerter rapidement une équipe d'intervention d'urgence.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-grey">
                                <div className="list-items-img">
                                    <h4 className="mt-2">Vérification de l'identité</h4>
                                    <p className="mt-2">Toutes les informations concernant les chauffeurs qui effectuent des courses avec Ongo sont à notre disposition.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 bg-grey">
                                <div className="list-items-img ">
                                    <h4 className="mt-2">Paiement flexible</h4>
                                    <p className="mt-2">Réglez vos courses en espèces, via Orange Money, MTN Mobile Money ou par carte bancaire.</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 bg-secondary">
                                <div className="list-items-img">
                                    <h4 className="mt-2 text-white">Partage du trajet</h4>
                                    <p className="mt-2">Partagez la marque, le modèle, la plaque d'immatriculation et la localisation en temps réel avec vos amis ou votre famille en utilisant un lien de partage. De plus, tous les trajets sont continuellement surveillés et enregistrés.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="app-banner-driver py-120 mt-100 w-100">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-lg-6">
                                <h1>Téléchargez gratuitement l’application et à bientôt sur les routes camerounaises avec ONGO !</h1>
                                <p className="mt-4">L'inscription ne vous prendra que quelques minutes !</p>

                                <div className="download-btn mt-20">
                                    <a href="https://play.google.com/store/search?q=ongo&c=apps&gl=FR">
                                        <i className="fab fa-google-play"></i>
                                        <div className="download-btn-content">
                                            {/*  <!--  <span>Get It On</span> --> */}
                                            <strong>Google Play</strong>
                                        </div>
                                    </a>
                                    <a href="https://apps.apple.com/fr/app/ongo/id1625930293">
                                        <i className="fab fa-app-store"></i>
                                        <div className="download-btn-content">
                                            {/*    <!--  <span>Get It On</span> --> */}
                                            <strong>App Store</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <img className="rounded" src="./assets/img/banner2.jpg" alt="" />
                            </div>

                        </div>
                    </div>

                </div>

                {/* <div className="bg-white py-100">
                    <div className="container">
                        <h1 className="mb-30">Questions fréquentes des clients</h1>
                        <div className="col-lg-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Puis-je conduire avec OnGo dans ma ville ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="heading1"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>OnGo est disponible dans des centaines de villes du cameroun. Consultez nos pages Villes pour voir si nous sommes disponibles pour commencer à conduire et augmenter vos revenus chez vous.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="true"
                                            aria-controls="collapseTwo"
                                        >
                                            Comment puis-je m'inscrire pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Augmentez vos revenus avec OnGo facilement ! Renseignez vos informations ci-dessous, téléchargez tous les documents requis et finalisez votre inscription dans notre Espace Partenaires. Une fois tout cela réalisé, vous serez prêt à augmenter vos revenus selon vos propres horaires !</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTree">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTree"
                                            aria-expanded="true"
                                            aria-controls="collapseTree"
                                        >
                                            Ai-je besoin d'une voiture pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTree"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Oui, mais si vous ne possédez pas de voiture, pas d'inquiétude — d’autres options sont disponibles. Nos équipes locales peuvent vous aider à trouver des flottes partenaires ou des sociétés de location qui offrent des tarifs compétitifs sur les véhicules que nous acceptons.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFor">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFor"
                                            aria-expanded="true"
                                            aria-controls="collapseFor"
                                        >
                                            Ai-je besoin d'un smartphone pour conduire avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFor"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFor"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Vous devez disposer d'un smartphone fonctionnant au moins sous Android 9.0 ou iOS 14. Si vous n'avez pas de smartphone, notre équipe peut vous conseiller sur un téléphone Android économique dont vous aurez besoin pour utiliser l'application OnGo Driver.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="true"
                                            aria-controls="collapseFive"
                                        >
                                            Peut-on augmenter ses revenus avec OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>L’avantage de conduire sur la plate-forme OnGo est que vous pouvez gagner de l’argent selon votre emploi du temps, ce qui en fait l’activité complémentaire idéale. Il n'y a pas de minimum d'heures, conduisez toute la semaine ou seulement le soir ou le week-end. Gagnez un revenu supplémentaire chaque fois que vous en avez besoin. Tu es le patron!</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSixe">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSixe"
                                            aria-expanded="true"
                                            aria-controls="collapseSixe"
                                        >
                                            Comment suis-je payé pour mes courses réalisées sur OnGo ?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSixe"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingSixe"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <p>Notre cycle de paiement s’étend du lundi minuit au dimanche suivant à 23:59, après quoi vous recevrez un relevé de solde. Vous recevrez vos paiements chaque semaine — pas besoin d'attendre la fin du mois.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </>
    )
};