import React, { FC } from "react";

import { Outlet } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";

interface Props {
  // any props that come into the component
}

const Layouts: FC<Props> = () => {
  return (
    <>
      <div id="app">
        <Header />
        <div id="main">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layouts;
