import { useEffect, useState } from "react";
import ApiService from "../../services/api-service";
import "./css.css";

const Api = new ApiService();

function Statistique() {
  const [stat, setData] = useState<any>();
  const [isLoad, setLoader] = useState<boolean>(true);
  const [datedebut, setdatedebut] = useState<any>();
  const [datefin, setdatefin] = useState<any>();
  const [active, setActive] = useState<boolean>(false);

  const getStat = async () => {
    try {
      var postdata = {
        debut: datedebut,
        fin: datefin,
      };

      setLoader(true);
      console.log(postdata);
      var response = await Api.getData("get-all-admin-stat");

      if (response.data.success) {

        console.log("============================");
        console.log(response.data.data);
        console.log(response.data.data);
        console.log("============================");

        setData(response.data.data);
        setLoader(false);

      } else {
      }
    } catch (error) {
    // setLoader(false);
    }
  };

  useEffect(() => {
    getStat();
  }, []);
  return (
    <>
      <script src="https://cdn.tailwindcss.com"></script>
      <div className="p-9">
        <div className="lg:flex lg:items-center lg:justify-between ">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mt-9">
              Ongo Quick stat backoffice 1
            </h2>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-0">
            <span className="sm:ml-3">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <svg
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"
                  />
                </svg>
                Aujourd'hui
              </button>
            </span>

            <div className="relative ml-3 ">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                id="mobile-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                onClick={() => setActive(!active)}
              >
                More
                <svg
                  className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <div
                className={
                  active
                    ? " absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    : "active-menu  absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                }
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="mobile-menu-button"
                tabIndex={-1}
              >
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabIndex={-1}
                  id="mobile-menu-item-0"
                >
                  Aujourd'hui
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabIndex={-1}
                  id="mobile-menu-item-1"
                >
                  Cette semaine
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabIndex={-1}
                  id="mobile-menu-item-1"
                >
                  Ce mois
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabIndex={-1}
                  id="mobile-menu-item-1"
                >
                  Cette année
                </a>
              </div>
            </div>
          </div>
        </div>
        {isLoad ? (
          <>loading</>
        ) : (
          <>
            {" "}
            <div date-rangepicker className="flex items-center pr-5 pl-0 mt-5">
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <input
                  name="start"
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  "
                  placeholder="Select date start"
                />
              </div>
              <span className="mx-2 text-gray-500">A</span>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <input
                  name="end"
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  "
                  placeholder="Select date end"
                />
              </div>
            </div>
            <div className="md:flex flex-col md:flex-row sm:flex-row items-center mt-10">
              <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                <div className="p-8">
                  <h1 className="block mt-1 text-lg leading-tight font-medium text-black text-center">
                    Commandes
                  </h1>
                  <div className="uppercase tracking-wide text-xl text-indigo-500 font-semibold text-center pt-5 pb-5">
                  { stat.course.total }
                  </div>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
       {/*          <p className="mt-2 text-gray-500">
            Getting a new business off the ground is a lot of hard work. Here
            are five ideas you can use to find your first customers.
          </p>  */}
                  <div className=" items-center">
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Aujourd'hui
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.course.dailyCompletedCount}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Cet semaine
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.course.weeklyCompletedCount }
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Ce mois
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.course.monthlyCompletedCount }
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Cette année
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.course.yearlyCompletedCount }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex flex-col md:flex-row sm:flex-row items-center mt-10">
              <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                <div className="p-8">
                  <h1 className="block mt-1 text-lg leading-tight font-medium text-black text-center">
                    Téléchargement
                  </h1>
                  <div className="uppercase tracking-wide text-xl text-indigo-500 font-semibold text-center pt-5 pb-5">
                      {stat.utilisateur.total} 
                  </div>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                  {/*   <p className="mt-2 text-gray-500">
            Getting a new business off the ground is a lot of hard work. Here
            are five ideas you can use to find your first customers.
          </p> */}
                  <div className=" items-center">
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Aujourd'hui
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      {stat.utilisateur.dailyCount} 
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Cet semaine
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.utilisateur.weeklyCount 
}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Ce mois
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.utilisateur.monthlyCount 
}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Cette année
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.utilisateur.yearlyCount 
}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex flex-col md:flex-row sm:flex-row items-center mt-10">
              <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                <div className="p-8">
                  <h1 className="block mt-1 text-lg leading-tight font-medium text-black text-center">
                    Course Annulée
                  </h1>
                  <div className="uppercase tracking-wide text-xl text-indigo-500 font-semibold text-center pt-5 pb-5">
                  { stat.courseannule.total }
                  </div>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                  {/*   <p className="mt-2 text-gray-500">
            Getting a new business off the ground is a lot of hard work. Here
            are five ideas you can use to find your first customers.
          </p> */}
                  <div className=" items-center">
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Aujourd'hui
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.courseannule.dailyCompletedCount}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Cet semaine
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.courseannule.weeklyCompletedCount}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Ce mois
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.courseannule.monthlyCompletedCount}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Cette année
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.courseannule.yearlyCompletedCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex flex-col md:flex-row sm:flex-row items-center mt-10">
              <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                <div className="p-8">
                  <h1 className="block mt-1 text-lg leading-tight font-medium text-black text-center">
                    Course terminée
                  </h1>
                  <div className="uppercase tracking-wide text-xl text-indigo-500 font-semibold text-center pt-5 pb-5">
                  { stat.coursetermine.total 
}
                  </div>
                  <hr />
                  <hr />
                  <hr />
                  <hr />
                  {/*   <p className="mt-2 text-gray-500">
            Getting a new business off the ground is a lot of hard work. Here
            are five ideas you can use to find your first customers.
          </p> */}
                  <div className=" items-center">
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Aujourd'hui
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.coursetermine.dailyCompletedCount
}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Cet semaine
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.coursetermine.weeklyCompletedCount}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Ce mois
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.coursetermine.monthlyCompletedCount}
                      </div>
                    </div>
                    <div className="p-3 flex space-between ">
                      <div className="block mt-1 text-md leading-tight font-medium text-black text-center">
                        Cette année
                      </div>
                      <div className="uppercase tracking-wide text-md text-indigo-500 font-semibold text-center ">
                      { stat.coursetermine.yearlyCompletedCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Statistique;
