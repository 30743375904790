import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

function PersonalInfo(props:any) {
 
  return (
    <div>
      {" "}
     
        <div className="user-profile-card">
          <h4 className="user-profile-card-title">Profile Info</h4>
          <div className="user-profile-form">
            <form action="#">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.userData.nom}
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.userData.prenom}
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.userData.email}
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.userData.telephone}
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value="New York, USA"
                      placeholder="Address"
                    />
                  </div>
                </div>
              </div>
              <button type="button" className="theme-btn my-3">
                <span className="far fa-user"></span> Save Changes
              </button>
            </form>
          </div>
        </div>
    
    </div>
  );
}

export default PersonalInfo;
