import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Menu() {
  const [active, setActive] = useState<number>(1);
  const logOut = () => {
    localStorage.removeItem("hashkey");
    window.location.replace("/");
  };

  const navigate = (link: string, state: number) => {
    setActive(state);
  };

  useEffect(() => {
    console.log(window.location.href);
  }, []);
  return (
    <div>
      {" "}
      <ul className="user-profile-sidebar-list">
        <li>
          <a
            className={window.location.hash === "#/agence/home" ? "active" : ""}
            href="#/agence/home"
          >
            <i className="far fa-gauge-high"></i> Dashboard
          </a>
        </li>
        {/*    <li>
          <Link
            className={
              window.location.hash === "#/agence/profil" ? "active" : ""
            }
            to="#/agence/profil"
          >
            <i className="far fa-user"></i> Mon profile
          </Link>
        </li> */}
        <li>
          <a
            className={
              window.location.hash === "#/agence/flotte" ? "active" : ""
            }
            href="#/agence/flotte"
          >
            <i className="far fa-layer-group"></i> Ma flotte
          </a>
        </li>
        <li>
          <Link
            className={
              window.location.hash === "#/agence/booking" ? "active" : ""
            }
            to="/agence/booking"
          >
            <i className="far fa-layer-group"></i> Mes location
          </Link>
        </li>
        {/*     <li>
        <Link 
            className={window.location.hash  === "#/agence/cancel/booking" ? "active" : ""}
            to="/agence/cancel/booking"
          >
            <i className="far fa-xmark-circle"></i> Cancel Booking
          </Link>
        </li>
        <li>
        <Link 
            className={window.location.hash  === "#/agence/payment" ? "active" : ""}
            to="/agence/payment"
          >
            <i className="far fa-credit-card"></i> Payment History
          </Link>
        </li>
        <li>
          <a
            className={active === 6 ? "active" : ""}
            onClick={() => navigate("agence/settings", 1)}
          >
            <i className="far fa-gear"></i> Settings
          </a>
        </li> */}
        <li>
          <a href="#" onClick={() => logOut()}>
            <i className="far fa-sign-out"></i> Logout
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
