import React, { useEffect, useState } from "react";
import PersonalInfo from "../../components/agence/personal-info";
import ChangePassword from "../../components/agence/change-password";
import SideMenu from "../../components/agence/side-menu";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../../features/auth/agence";

function Agence() {
  const dispatch = useDispatch();
  const useinfo = useSelector((state: any) => state.user.data);

  useEffect(() => {
    dispatch<any>(getUserData());
    console.log(useinfo);
  }, [dispatch]);
  return (
    <div>
      {" "}
      <main className="main">
        <div className="user-profile py-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
               <SideMenu userData={useinfo}/>
              </div>
              <div className="col-lg-9"> 
                <div className="user-profile-wrapper">
                  <div className="row">
                    <div className="col-lg-7">
                      <PersonalInfo userData={useinfo}/>
                    </div>
                    <div className="col-lg-5">
                      <ChangePassword />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Agence;
