import React, { useState } from "react";
import ApiService from "../../services/api-service";
import './login.css'

function Login() {
  const Api = new ApiService();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      var postdata = { telephone: formData.email, password: formData.password };
      setLoading(true);

      var response = await Api.postData("utilisateur/login", postdata);
      console.log(response);

      if (response.data.success) {
        console.log(response.data.data);

        localStorage.setItem(
          "isAuthenticated",
          JSON.stringify(response.data.data)
        );
        localStorage.setItem("hashkey", response.data.data.token);
        setLoading(false);
        window.location.replace("#/agence/home");
      } else {
        console.log(response.data);

        setLoading(false);
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error);

      setLoading(false);
      alert("Nom d'utilisateur ou mot de passe incorrect");
    }

    // Here, you can perform any action with the form data, like sending it to a server or processing it further.
    console.log(formData);
  };

  return (
    <main className="main">
      <div className="login-area bg-primary banner-gradient">
        <div className="container py-120">
          <div className="col-md-6 mx-auto">
            <div className="login-form">
              <div className="login-header">
                <img src="./assets/img/logo-trs.png" alt="" />
                <p>Espace partenaires • Accès sécurisé</p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="text-white">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Votre email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="text-white">Mot de passe</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Votre mot de passe"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                {/*  <div className="d-flex justify-content-between mb-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="remember"
                  />
                  <label className="form-check-label" htmlFor="remember">
                    Remember Me
                  </label>
                </div>
                <a href="forgot-password.html" className="forgot-pass"
                  >Forgot Password?</a
                >
              </div> */}
                <div className="d-flex align-items-center">
                  {/*      <!--  <button type="submit" className="theme-btn">   <i className="fa fa-sign-in"></i> Login
              </button> --> */}
                  {loading ? (
                    <a className="theme-btn">Traitement ...</a>
                  ) : (
                    <button className="theme-btn" type="submit">
                      <i className="fa fa-sign-in"></i> Me connecter
                    </button>
                  )}
                </div>
              </form>
              {/*  <div className="login-footer">
              <p>
                Don't have an account?
                <a href="register.html">Contacter le service support.</a>
              </p>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
