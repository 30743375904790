import React from "react";
import HeaderTop from "./header-top";
import HeaderNav from "./header-nav";

function Header() {
  return (
    <>
      <header className="header">
        {/* <HeaderTop /> */}
        <HeaderNav />
      </header>
    </>
  );
}

export default Header;
