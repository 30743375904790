import React from "react";
import Menu from "./menu";

function SideMenu(props: any) {
  
  return (
    <div>
      {" "}
      <div className="user-profile-sidebar">
        <div className="user-profile-sidebar-top">
          <div className="user-profile-img">
            <img src="assets/img/account/user.jpg" alt="" />
            <button type="button" className="profile-img-btn">
              <i className="far fa-camera"></i>
            </button>
            <input type="file" className="profile-img-file" />
          </div>
          <h5>
            {props.userData.nom} {props.userData.prenom}
          </h5>
          <p>
            <a
              href="/cdn-cgi/l/email-protection"
              className="__cf_email__"
              data-cfemail="e988879d868780a98c91888499858cc78a8684"
            >
              {props.userData.email}
            </a>
          </p>
        </div>
        <Menu/>
      </div>
    </div>
  );
}

export default SideMenu;
