import React from "react";

export default function ContactUs() {
  return (
    <>
      <div
        className="site-breadcrumb"
        style={{ backgroundImage: "#031932;" }}
      // style="background: url(assets/img/breadcrumb/01.jpg)"
      >
        <div className="container">
          <h2 className="breadcrumb-title">Nous Contacter</h2>
          {/* <ul className="breadcrumb-menu">
            <li className="active">Nous Contacter</li>
          </ul> */}
        </div>
      </div>

      <div className="contact-area py-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h1 className="mb-20 mt-20">Première application de chauffeurs privés agrée 100% camerounaise</h1>
              <p className="mb-50">Indiquez votre destination, confirmez votre course, rencontrez votre chauffeur et profitez de votre trajet en toute sécurité!</p>
            </div>
            <div className="col-lg-6 text-center">
              <img className="" src="./assets/img/company-img.png" alt="" />
            </div>
          </div>
          <hr className="mt-5" />
          <div className="contact-content">
            <div className="row">
              <div className="col-md-3">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fa fa-map-location-dot"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5> Address</h5>
                    <p>
                      (YAOUNDÉ) Rue ONAMBELE NIKOU, quartier NLONGKAK - Yaoundé
                      1
                    </p>
                    <hr />
                    <p>
                      (DOUALA) Immeuble CAMAIR, Avenue de Gaulle, au lieu-dit
                      BONANJO
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fa fa-phone-volume"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Call Us</h5>
                    <p>(+237) 659 55 00 00</p> <hr /> <p>(+237) 650 132 537</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fa fa-envelope"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Email Us</h5>
                    <p>
                      <a
                        href="/cdn-cgi/l/email-protection"
                        className="__cf_email__"
                        data-cfemail="98f1f6fef7d8fde0f9f5e8f4fdb6fbf7f5"
                      >
                        contact@ongo237.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fa fa-clock"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Open Time</h5>
                    <p>7 J/7 - 24 H / 24 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-wrapper">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="contact-img">
                  <img
                    src="./assets/img/3.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="contact-form">
                  <div className="contact-form-header">
                    <h2>Get In Touch</h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page randomised
                      words slightly when looking at its layout.
                    </p>
                  </div>
                  <form
                    method="post"
                    action="/taxica/assets/php/contact.php"
                    id="contact-form"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Your Email"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="Your Subject"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        cols={30}
                        rows={5}
                        className="form-control"
                        placeholder="Write Your Message"
                      ></textarea>
                    </div>
                    <button type="submit" className="theme-btn">
                      Send Message <i className="far fa-paper-plane"></i>
                    </button>
                    <div className="col-md-12 mt-3">
                      <div className="form-messege text-success"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96708.34194156103!2d-74.03927096447748!3d40.759040329405195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a01c8df6fb3cb8!2sSolomon%20R.%20Guggenheim%20Museum!5e0!3m2!1sen!2sbd!4v1619410634508!5m2!1sen!2s"
          style={{ border: "0" }}
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
}
