import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AgenceService from "../../services/auth/agence-service";
import FlotteService from "../../services/flotte/flotte-service";

// Asynchronous action creator using createAsyncThunk
export const getUserData = createAsyncThunk("user/getUserData", async () => {
  try {
    const location = await new AgenceService().userDataAgence();
  
    return location;
  } catch (error) {
    console.error("Error fetching :", error);
    throw error;
  }
});

export const getUserDataList = createAsyncThunk("user/getUserDataList", async (url:any=null) => {
  try {
    const locationList = await new AgenceService().userDataAgenceList(url);
    return locationList;
  } catch (error) {
    throw error;
  }
});


export const getFlotteDataList = createAsyncThunk("flotte/getFlotteDataList", async () => {
  try {
    const flotteList = await new FlotteService().FlotteDataList();
    console.log("flotteList");
    console.log(flotteList);
    
    return flotteList;
  } catch (error) {
    throw error;
  }
});
export const utilisateurData = createSlice({
  name: "user",
  initialState: {
    loading: false,
    data: {},
    locationList: [],
    links:[],
    flotteList: [],

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.pending, (state) => {
        // Handle pending state, e.g., showing a loading spinner
        state.data = {};
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        // Handle fulfilled state, update the state with the fetched data
     
        state.data = action.payload.data;
      })
      .addCase(getUserData.rejected, (state, action) => {
        // Handle rejected state, e.g., show an error message
        state.data = [];
      });
      builder
      .addCase(getUserDataList.pending, (state) => {
        // Handle pending state, e.g., showing a loading spinner
        console.log(state);console.log("pending");
        state.locationList = [];
      })
      .addCase(getUserDataList.fulfilled, (state, action) => {
        // Handle fulfilled state, update the state with the fetched data
        console.log(" payload ok");
        console.log(action.payload.data.links);
        console.log(" payload");
        state.locationList = action.payload.data.data;
        state.links = action.payload.data.links;
      })
      .addCase(getUserDataList.rejected, (state, action) => {
        // Handle rejected state, e.g., show an error message
        console.log("rejected");
        console.log(state);
        state.locationList = [];
      });

      builder
      .addCase(getFlotteDataList.pending, (state) => {
        // Handle pending state, e.g., showing a loading spinner
        console.log(state);console.log("pending");
        state.flotteList = [];
      })
      .addCase(getFlotteDataList.fulfilled, (state, action) => {
        // Handle fulfilled state, update the state with the fetched data
        console.log(" payload ok");
        console.log(action.payload.data);
        if(action.payload.data==null){
          state.flotteList = [];

        }else{
          console.log(" payload");
          state.flotteList = action.payload.data.myfleets;
        }
    
        //state.links = action.payload.data.links;
      })
      .addCase(getFlotteDataList.rejected, (state, action) => {
        // Handle rejected state, e.g., show an error message
        console.log("rejected");
        console.log(state);
        state.flotteList = [];
      });
  },
});

export default utilisateurData.reducer;
